<template>
  <v-card-text>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12" md="4">
          <label for="load">{{ $t('filters.load') }}</label>
          <LocationInput
            v-model="filters.locations.loadingPlaces[0].value"
            class="mb-2"
            clearable
          />
          <DatePicker
            v-model="filters.minPublishDate.value"
            :label="$t('filters.dateFrom')"
            :rules="[validations.start]"
          />
        </v-col>
        <v-col cols="12" md="4">
          <label for="unload">{{ $t('filters.unload') }}</label>
          <LocationInput
            v-model="filters.locations.unloadingPlaces[0].value"
            class="mb-2"
            clearable
          />
          <DatePicker
            v-model="filters.maxPublishDate.value"
            :label="$t('filters.dateTo')"
            :min="filters.minPublishDate.value"
            :rules="[validations.start]"
          />
        </v-col>
        <v-col cols="12" md="4">
          <v-row class="mt-1">
            <v-col cols="6">
              <v-btn color="primary" outlined block @click="clearFilters">
                {{ $t('filters.clear') }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn color="primary" outlined block @click="addFilter">
                {{ $t('filters.setFilter') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-btn color="primary" outlined block @click="displayMoreFiltersDialog = !displayMoreFiltersDialog">
                {{ $t('filters.more') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <MoreFrachtFiltersDialog
      v-if="displayMoreFiltersDialog"
      v-model="displayMoreFiltersDialog"
      :data="filters"
      :currentTabName="currentTabName"
      @change-filters="changeFilters"
    />
  </v-card-text>
</template>

<script>
import DatePicker from '@/components/forms/DatePicker'
import MoreFrachtFiltersDialog from '@/views/Market/dialogs/MoreFrachtFiltersDialog'
import {
  LOCATIONCROSS,
  MAX,
  MIN,
  MULTI,
  MYCOMPANY,
  NEGOTIATEDBYCOMPANY,
  ONLYMINE,
  SEARCH,
  DEALBYCOMPANY,
  DEALBYUSER
} from '@/types/condition-types'
import { DATETIME, DECIMAL, MEASUREDATETIME } from '@/types/filter-value-types'
import LocationInput from '@/components/forms/LocationInput'
import { convertDataToFilters, convertFilters, createNewPlace } from '@/utils/market'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'FrachtsFilter',
  components: {
    DatePicker,
    MoreFrachtFiltersDialog,
    LocationInput
  },
  data () {
    return {
      valid: true,
      displayMoreFiltersDialog: false,
      filters: {
        minStart: {
          type: MIN,
          name: 'Start',
          valueType: DATETIME,
          value: null
        },
        minEnd: {
          type: MIN,
          name: 'End',
          valueType: DATETIME,
          value: null
        },
        locations: {
          type: LOCATIONCROSS,
          loadingPlaces: [
            { value: createNewPlace() }
          ],
          unloadingPlaces: [
            { value: createNewPlace() }
          ]
        },
        companyPayerStatus: {
          type: MULTI,
          name: 'CompanyPayerStatus',
          value: null
        },
        type: {
          type: MULTI,
          name: 'Type',
          value: null
        },
        vehicleFreightTypes: {
          type: MULTI,
          name: 'VehicleFreightTypes',
          value: null
        },
        negotiatedByCompany: {
          type: NEGOTIATEDBYCOMPANY,
          companyId: null
        },
        name: '',
        onlyMine: {
          type: ONLYMINE,
          value: false
        },
        dealByCOmpany: {
          type: DEALBYCOMPANY,
          companyId: null
        },
        dealByUser: {
          type: DEALBYUSER,
          userId: null
        },
        myCompany: {
          type: MYCOMPANY,
          value: false
        },
        minPublishDate: {
          type: MIN,
          name: 'PublishDate',
          valueType: MEASUREDATETIME,
          value: null
        },
        maxPublishDate: {
          type: MAX,
          name: 'PublishDate',
          valueType: MEASUREDATETIME,
          value: null
        },
        minHeight: {
          type: MIN,
          name: 'Height',
          valueType: DECIMAL,
          value: null
        },
        minLoad: {
          type: MIN,
          name: 'Load',
          valueType: DECIMAL,
          value: null
        },
        maxLoad: {
          type: MAX,
          name: 'Load',
          valueType: DECIMAL,
          value: null
        },
        minFreightWeight: {
          type: MIN,
          name: 'FreightWeight',
          valueType: DECIMAL,
          value: null
        },
        maxFreightWeight: {
          type: MAX,
          name: 'FreightWeight',
          valueType: DECIMAL,
          value: null
        },
        maxStart: {
          type: MAX,
          name: 'Start',
          valueType: DATETIME,
          value: null
        },
        maxEnd: {
          type: MAX,
          name: 'End',
          valueType: DATETIME,
          value: null
        },
        minLoadMeters: {
          type: MIN,
          name: 'LoadMeters',
          valueType: DECIMAL,
          value: null
        },
        minWidth: {
          type: MIN,
          name: 'Width',
          valueType: DECIMAL,
          value: null
        },
        minVolume: {
          type: MIN,
          name: 'Volume',
          valueType: DECIMAL,
          value: null
        },
        size: {
          type: MULTI,
          name: 'Size',
          value: null
        },
        loadForms: {
          type: MULTI,
          name: 'LoadForms',
          value: null
        },
        options: {
          type: MULTI,
          name: 'Options',
          value: null
        },
        publisherId: {
          type: MULTI,
          name: 'PublisherId',
          value: null
        },
        paymentType: {
          type: MULTI,
          name: 'PaymentType',
          value: null
        },
        search: {
          type: SEARCH,
          value: null
        }
      },
      validations: {
        start: () => (this.filters.minStart.value && this.filters.minStart.value.length > 0 && this.filters.maxStart.value && this.filters.maxStart.value.length > 0) ? moment(this.filters.minStart.value).unix() <= moment(this.filters.maxStart.value).unix() : true
      },
      currentTabName: null
    }
  },
  computed: {
    ...mapGetters({
      currentTab: 'market/currentTab'
    })
  },
  watch: {
    currentTab () {
      this.currentTabName = this.currentTab.hardcodedKey
      this.filters = convertDataToFilters(this.currentTab)
    }
  },
  async created () {
    this.currentTabName = this.currentTab.hardcodedKey
    this.filters = convertDataToFilters(this.currentTab)
  },
  methods: {
    async addFilter () {
      if (!this.$refs.form.validate()) {
        return
      }

      const conditions = {
        ...this.filters,
        locations: {
          ...this.filters.locations,
          loadingPlaces: this.filters.locations.loadingPlaces.map(i => ({ ...i.value })),
          unloadingPlaces: this.filters.locations.unloadingPlaces.map(i => ({ ...i.value }))
        }
      }
      const filters = convertFilters(this.$store.getters['market/orderType'], conditions)
      await this.$store.dispatch('market/submitFilter', { model: { ...filters } })
    },
    async clearFilters () {
      const filters = convertFilters(this.$store.getters['market/orderType'], [])
      await this.$store.dispatch('market/submitFilter', { model: { ...filters } })
    },
    async changeFilters (filters) {
      this.filters = filters
      await this.addFilter()
    }
  }
}
</script>

<style scoped>

</style>
